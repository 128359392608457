import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import currencyUtils from 'utils/currency'

import { addMinutes } from 'date-fns'
import { initShoppingExpress, AddItemToCart } from 'services/villageServices'
import { Container, Spacing50 } from 'styles/sharedStyle'
import { Title60 } from 'components/Typography'
import theme from 'styles/theme'
import Layout from 'components/Layout'
import Hero from 'components/Hero'

import ServiceBasket from 'components/ServiceBasket/ServiceBasket'

import {
  useForm,
  FormDatepicker,
  FormRadioGroup,
  FormDropdown,
  FormQuantitySelector,
  FormText,
} from 'components/Forms'
import Button from 'components/Button'
import { serviceType } from 'utils/contentUtils'

const ShoppingExpressBookingPage = (props) => {
  const {
    pageContext: { villageServicesEndPoint, pageLevel },
    data: {
      page: {
        hero,
        formMetaData,
        locale,
        // @ts-ignore
        village,
        village: { code },
        jsonLdSchema,
      },
    },
  } = props
  const labels = formMetaData.find(
    (metaData) => metaData.__typename === 'ContentfulLabelShoppingExpressLab07'
  )

  const buttonAction = {
    checkout: 'checkout',
    addService: 'addService',
  }

  const participantsTypes = {
    adult: 'NoOfAdults',
    children: 'NoOfChild',
    infants: 'NoOfInfant',
    family: 'NoOfFamily',
  }

  const nameFields = {
    departureLocation: 'departureLocation',
    departureTime: 'departureTime',
    returnTime: 'returnTime',
    participants: 'participants',
    promoCode: 'promoCode',
  }

  const initialValues = {
    dateOfTravel: {
      name: 'dateOfTravel',
      label: labels.dateOfTravel,
      value: addMinutes(new Date(), 1440),
      startDate: addMinutes(new Date(), 1440),
      daysOfWeek: [],
      blockedDates: [],
      isDisabled: true,
      locale,
    },
    journeyType: {
      name: 'journeyType',
      label: labels.journeyType,
      value: '2',
      options: [
        {
          id: '1',
          name: 'Single',
          value: '1',
          available: true,
        },
        {
          id: '2',
          name: 'Return',
          value: '2',
        },
      ],
      required: false,
      hasError: false,
      errorMessage: 'Journey type required',
      isDisabled: true,
    },
    departureLocation: {
      label: labels.departureLocation,
      name: nameFields.departureLocation,
      value: '',
      options: [],
      hasError: false,
      errorMessage: 'Departure location required',
      isDisabled: true,
    },
    departureTime: {
      label: labels.departureTime,
      name: nameFields.departureTime,
      value: '',
      options: [],
      hasError: false,
      errorMessage: 'Departure time required',
      isDisabled: true,
    },
    returnTime: {
      label: labels.returnTime,
      name: nameFields.returnTime,
      value: '',
      options: [],
      hasError: false,
      errorMessage: 'Return time required',
      isDisabled: true,
    },
    participants: {
      label: '',
      name: nameFields.participants,
      options: [
        {
          name: participantsTypes.adult,
          label: 'Adult',
          description: '',
          value: 1,
          available: true,
          price: 0,
        },
        {
          name: participantsTypes.children,
          label: 'Children',
          description: '',
          value: 0,
          available: true,
          price: 0,
        },
        {
          name: participantsTypes.infants,
          label: 'Infants',
          description: '',
          value: 0,
          available: true,
          price: 0,
        },
        {
          name: participantsTypes.family,
          label: 'Family',
          description: '',
          value: 0,
          available: true,
          price: 0,
        },
      ],
      required: true,
      hasError: false,
      isDisabled: true,
      errorMessage: 'You need to add at least one adult or one family ticket',
    },
    promoCode: {
      label: labels.promoCode,
      name: nameFields.promoCode,
      value: undefined,
      hasError: false,
      isDisabled: false,
    },
  }

  const serviceBasketProps = {
    checkoutLabel: 'Checkout Now',
    checkoutUrl: '/checkout',
    ctaLabel: 'Browse More Services',
    ctaUrl: '/services',
    emptyText: `You haven't added any services yet.`,
    expressLabel: 'Shopping Express',
    myServices: 'My Services',
    totalLabel: 'Total',
  }

  // const [basket, setBasket] = useState()
  const [journeys, setJourneys] = useState()
  const [availability, setAvailability] = useState()
  const [villageValue, setVillage] = useState()
  const [isFetching, setIsFetching] = useState(true)
  const [hasError, setHasError] = useState(false)
  const {
    form,
    handleUpdate,
    handleChange,
    handleDecrement,
    handleIncrement,
  } = useForm(initialValues)

  const handleUpdatedTimes = (e) => {
    const { name } = e.target

    const locationId =
      name === nameFields.departureLocation && e.target.value !== ''
        ? parseInt(e.target.value, 10)
        : form.departureLocation.value
    let departureTimeId
    let returnTimeId

    const newObj = journeys[0].Routes.filter(
      (key) => key.LocationId === locationId
    )
    const departureTimeOptions = newObj
      .reduce((departureGroup, departureItem) => {
        if (
          !departureGroup.some((key) => key.value === departureItem.RouteId)
        ) {
          departureGroup.push({
            value: departureItem.RouteId,
            name: departureItem.DepartureTime,
          })
        }
        return departureGroup
      }, [])
      .sort((item1, item2) => {
        if (item2.name > item1.name) return -1
        if (item2.name < item1.name) return 1
        return 0
      })
    if (name !== nameFields.departureLocation) {
      departureTimeId =
        name === nameFields.departureTime && e.target.value !== ''
          ? parseInt(e.target.value, 10)
          : form.departureTime.value
      if (name !== nameFields.departureTime) {
        returnTimeId =
          name === nameFields.returnTime && e.target.value !== ''
            ? parseInt(e.target.value, 10)
            : form.returnTime.value
      } else {
        returnTimeId = ''
      }
    } else {
      departureTimeId =
        departureTimeOptions.length === 1 ? departureTimeOptions[0].value : ''
      returnTimeId = ''
    }

    const returnTimeOptions = newObj
      .filter((key) => key.RouteId === departureTimeId)
      .reduce((returnGroup, returnTime) => {
        if (
          !returnGroup.some((key) => key.value === returnTime.LinkedRouteId)
        ) {
          returnGroup.push({
            value: returnTime.LinkedRouteId,
            name: returnTime.ReturnTime,
          })
        }
        return returnGroup
      }, [])
      .sort((item1, item2) => {
        if (item2.name > item1.name) return -1
        if (item2.name < item1.name) return 1
        return 0
      })

    returnTimeId =
      returnTimeOptions.length === 1 ? returnTimeOptions[0].value : returnTimeId

    const pricesItems = newObj.filter(
      (value) =>
        value.RouteId === departureTimeId &&
        value.LinkedRouteId === returnTimeId
    )
    const prices = pricesItems.length > 0 ? pricesItems[0].Prices : []

    handleUpdate({
      ...form,
      departureLocation: {
        ...form.departureLocation,
        value: locationId,
        hasError: false,
      },
      departureTime: {
        ...form.departureTime,
        isDisabled: departureTimeOptions.length === 0,
        options:
          departureTimeOptions.length === 1
            ? [...departureTimeOptions]
            : [{ name: '', value: '' }, ...departureTimeOptions],
        value: departureTimeId,
        hasError: false,
      },
      returnTime: {
        ...form.returnTime,
        isDisabled: returnTimeOptions.length === 0,
        options:
          returnTimeOptions.length === 1
            ? [...returnTimeOptions]
            : [{ name: '', value: '' }, ...returnTimeOptions],
        value: returnTimeId,
        hasError: false,
      },
      participants: {
        ...form.participants,
        hasError: false,
        isDisabled: prices.length === 0,
        options:
          prices.length !== 0
            ? [
                {
                  ...form.participants.options[0],
                  description:
                    prices != null &&
                    prices.Adult &&
                    `${currencyUtils[villageValue.CurrencyCode](
                      prices.Adult.Price
                    )} ${prices.Adult.Description}`,
                  available: true,
                  price: prices && prices.Adult && prices.Adult.Price,
                },
                {
                  ...form.participants.options[1],
                  description:
                    prices != null &&
                    prices.Children &&
                    `${currencyUtils[villageValue.CurrencyCode](
                      prices.Children.Price
                    )} ${prices.Children.Description}`,
                  available: !!availability.IsChildTicketsAvailable,
                  price: prices && prices.Children && prices.Children.Price,
                },
                {
                  ...form.participants.options[2],
                  description:
                    prices != null &&
                    prices.Infant &&
                    `${currencyUtils[villageValue.CurrencyCode](
                      prices.Infant.Price
                    )} ${prices.Infant.Description}`,
                  available: !!availability.IsInfantTicketsAvailable,
                  price: prices && prices.Infant && prices.Infant.Price,
                },
                {
                  ...form.participants.options[3],
                  description:
                    prices != null &&
                    prices.Family &&
                    `${currencyUtils[villageValue.CurrencyCode](
                      prices.Family.Price
                    )} ${prices.Family.Description}`,
                  available: !!availability.IsFamilyTicketsAvailable,
                  price: prices && prices.Family && prices.Family.Price,
                },
              ]
            : [
                {
                  ...form.participants.options[0],
                  label: 'adult',
                  description: '',
                  value: 1,
                },
                {
                  ...form.participants.options[1],
                  label: 'children',
                  description: '',
                  value: 0,
                },
                {
                  ...form.participants.options[2],
                  label: 'infants',
                  description: '',
                  value: 0,
                },
                {
                  ...form.participants.options[3],
                  label: 'family',
                  description: '',
                  value: 0,
                },
              ],
      },
    })
  }

  useEffect(() => {
    const getJourneys = () => {
      if (isFetching === true) {
        initShoppingExpress(villageServicesEndPoint, locale, code).then(
          (resp) => {
            if (
              resp.journeys.data &&
              resp.availability.data &&
              resp.village.data
            ) {
              setJourneys(resp.journeys.data.Journeys)
              setAvailability(resp.availability.data)
              setVillage(resp.village.data.data.Village)
            } else {
              setHasError(true)
            }
            setIsFetching(false)
          }
        )
      }

      if (
        journeys &&
        journeys.length > 0 &&
        isFetching === false &&
        hasError === false
      ) {
        const routeOptions = []
        const newObj = Object.assign({}, journeys[0].Routes)
        const arrayKeys = Object.keys(newObj)

        arrayKeys.forEach((key) => {
          if (routeOptions[newObj[key].LocationId] === undefined) {
            routeOptions[newObj[key].LocationId] = {
              value: newObj[key].LocationId,
              name: newObj[key].LocationName,
            }
          }
        })

        handleUpdate({
          ...form,
          dateOfTravel: {
            ...form.dateOfTravel,
            isDisabled: false,
            blockedDates: availability.BlockedDates,
            daysOfTheWeek: availability.DaysOfTheWeek,
          },
          journeyType: {
            ...form.journeyType,
            isDisabled: false,
            options: [
              {
                ...form.journeyType.options[0],
                available: availability.IsOneWayAvailable,
              },
              {
                ...form.journeyType.options[1],
              },
            ],
          },
          departureLocation: {
            ...form.departureLocation,
            isDisabled: false,
            options: [
              {
                value: '',
                name: '',
                isDisabled: true,
              },
              ...routeOptions,
            ],
          },
        })
      }
    }
    getJourneys()
  }, [isFetching])

  const clearForm = (date) => {
    handleUpdate({
      ...form,
      dateOfTravel: {
        ...form.dateOfTravel,
        value: date,
      },
      journeyType: {
        ...form.journeyType,
        value: '2',
      },
      departureLocation: {
        ...form.departureLocation,
        value: '',
        hasError: false,
      },
      departureTime: {
        ...form.departureTime,
        value: '',
        options: [],
        isDisabled: true,
        hasError: false,
      },
      returnTime: {
        ...form.returnTime,
        value: '',
        options: [],
        isDisabled: true,
        hasError: false,
      },
      participants: {
        ...form.participants,
        options: [
          {
            ...form.participants.options[0],
            label: 'adult',
            description: '',
            value: 1,
          },
          {
            ...form.participants.options[1],
            label: 'children',
            description: '',
            value: 0,
          },
          {
            ...form.participants.options[2],
            label: 'infants',
            description: '',
            value: 0,
          },
          {
            ...form.participants.options[3],
            label: 'family',
            description: '',
            value: 0,
          },
        ],
        isDisabled: true,
        hasError: false,
      },
    })
  }

  const handleDecrementCheckParticipants = (event, name, participants) => {
    handleDecrement(event, name, participants)

    const newObj = Object.assign({}, form.participants.options)

    if (
      (name === participantsTypes.family || name === participantsTypes.adult) &&
      participants === 1
    ) {
      if (
        (form.participants.options[0].available === true ||
          form.participants.options[3].available === true) &&
        form.participants.options[0].value === 0 &&
        form.participants.options[3].value === 0
      ) {
        newObj[1].value = 0
        newObj[2].value = 0
      }
    }

    handleUpdate({
      ...form,
    })
  }

  const handleIncrementCheckParticipants = (event, name, participants) => {
    handleIncrement(event, name, participants)

    const newObj = Object.assign({}, form.participants.options)

    if (
      (name === participantsTypes.children ||
        name === participantsTypes.infants) &&
      participants === 0
    ) {
      if (
        form.participants.options[0].available === true &&
        form.participants.options[0].value === 0 &&
        form.participants.options[3].value === 0
      ) {
        newObj[0].value = 1
      } else if (
        form.participants.options[3].available === true &&
        form.participants.options[3].value < 0
      ) {
        newObj[3].value = 1
      }
    } else if (name === participantsTypes.family) {
      newObj[0].value = 0
      newObj[1].value = 0
      newObj[2].value = 0
    }

    handleUpdate({
      ...form,
    })
  }

  const submitForm = () => {
    let formHasError = false

    Object.keys(form).map((key) => {
      if (form[key].value === '' && !form[key].isDisabled) {
        form[key].hasError = true
        formHasError = true
      }
      if (
        form[key].name === nameFields.participants &&
        !Object.keys(form[key].options).some(
          (item) => form[key].options[item].value > 0
        ) &&
        !form[key].isDisabled
      ) {
        form[key].hasError = true
        formHasError = true
      }
      return form[key]
    })

    if (formHasError)
      handleUpdate({
        ...form,
      })

    return !formHasError
  }

  const addToBasket = (event, url) => {
    if (event) event.preventDefault()
    if (submitForm()) {
      const rout = journeys[0].Routes.filter(
        (value) =>
          value.LocationId === form.departureLocation.value &&
          value.RouteId === form.departureTime.value &&
          value.LinkedRouteId === form.returnTime.value
      )
      const returnStop = rout[0].ReturnStops.filter(
        (value) => value.ReturnTime === rout[0].ReturnTime
      )

      const item = {
        $type:
          'VR.Integration.Common.Cart.Model.ShoppingExpressBasketItem, VR.Integration.Common.Cart',
        JourneyType: form.journeyType.value,
        DepartureTime: new Date(
          form.dateOfTravel.value.setHours(...rout[0].DepartureTime.split(':'))
        ),
        ReturnTime: new Date(
          form.dateOfTravel.value.setHours(...rout[0].ReturnTime.split(':'))
        ),
        RouteId: rout[0].RouteId,
        StopId: rout[0].StopId,
        ReturnRouteId: returnStop[0].RouteId,
        ReturnStopId: returnStop[0].StopId,
        ServiceType: serviceType.ShoppingExpress,
        ProductId: rout[0].RouteId,
        DateOfTravel: form.dateOfTravel.value,
        PromotionalCode: form.promoCode.value,
        Participants: [
          {
            Label: participantsTypes.adult,
            Value: form.participants.options[0].value,
          },
          {
            Label: participantsTypes.children,
            Value: form.participants.options[1].value,
          },
          {
            Label: participantsTypes.infants,
            Value: form.participants.options[2].value,
          },
          {
            Label: participantsTypes.family,
            Value: form.participants.options[3].value,
          },
        ],
      }

      AddItemToCart(villageServicesEndPoint, code, item).then((resp) => {
        if (resp.data.RequestStatus.Success) {
          // Success
          // eslint-disable-next-line no-alert
          alert(`Created -> redirect ${url}`)
        } else {
          // Error
          // eslint-disable-next-line no-alert
          alert(resp.data.RequestStatus.Errors[0].ErrorMessage)
        }
      })
    }
  }

  const media = hero && hero.media && hero.media[0]

  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        opacity: media.opacity,
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
      }
    : {}
  const heroProps = hero
    ? {
        eyebrow: hero.eyebrow,
        content: hero.headline,
        image: heroImage,
        video: heroVideo,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const {
    dateOfTravel,
    journeyType,
    departureLocation,
    departureTime,
    returnTime,
    participants,
    promoCode,
  } = form

  return (
    <Layout {...props} journeys={journeys} availability={availability}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>

      {heroProps && <Hero {...heroProps} />}

      <Spacing50 />

      <Container maxWidth={[780]} textAlign="center">
        <Title60 pt={['60px', null, '80px']} color={theme.colors.sagedark}>
          {labels.heading}
        </Title60>
      </Container>

      {hasError ? (
        <Container py={[60]} textAlign="center" maxWidth={[380]}>
          Oops something went wrong...
        </Container>
      ) : (
        <Container>
          <Container maxWidth={[380]}>
            <FormDatepicker
              {...dateOfTravel}
              handleDateChange={(date) => clearForm(date)}
              value={dateOfTravel.value}
            />

            <FormRadioGroup
              {...journeyType}
              handleChange={(e) => handleChange(e, 'journeyType')}
              value={journeyType.value}
              isDisabled={journeyType.isDisabled}
              hasError={journeyType.hasError}
            />

            <FormDropdown
              {...departureLocation}
              handleChange={handleUpdatedTimes}
              value={departureLocation.value}
              isDisabled={departureLocation.isDisabled}
              hasError={departureLocation.hasError}
              options={departureLocation.options}
            />

            <FormDropdown
              {...departureTime}
              handleChange={handleUpdatedTimes}
              value={form.departureTime.value}
              isDisabled={form.departureTime.isDisabled}
              hasError={form.departureTime.hasError}
            />

            <FormDropdown
              {...returnTime}
              handleChange={handleUpdatedTimes}
              value={form.returnTime.value}
              isDisabled={form.returnTime.isDisabled}
              hasError={form.returnTime.hasError}
            />
          </Container>

          <Container maxWidth={[780]} textAlign="center">
            <Title60 pt={['60px', null, '80px']} color={theme.colors.sagedark}>
              {labels.ticketTypesHeading}
            </Title60>
          </Container>

          <Container maxWidth={[380]}>
            <FormQuantitySelector
              {...participants}
              handleDecrement={handleDecrementCheckParticipants}
              handleIncrement={handleIncrementCheckParticipants}
              isDisabled={participants.isDisabled}
              hasError={participants.hasError}
            />

            <FormText
              {...promoCode}
              value={promoCode.value}
              handleChange={handleChange}
            />

            <Button
              type="submit"
              width="100%"
              level="primary"
              onClick={(e) => addToBasket(e, buttonAction.addService)}
              mt={theme.space[3]}>
              {labels.addToMyServices}
            </Button>

            <Button
              type="submit"
              width="100%"
              level="secondary"
              onClick={(e) => addToBasket(e, buttonAction.checkout)}
              mt={theme.space[3]}
              mb={theme.space[12]}>
              {labels.checkoutNow}
            </Button>
          </Container>
        </Container>
      )}

      <ServiceBasket {...serviceBasketProps} />
    </Layout>
  )
}

ShoppingExpressBookingPage.propTypes = {
  pageContext: PropTypes.shape({
    pageLevel: PropTypes.string.isRequired,
    villageServicesEndPoint: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

// eslint-disable-line no-unused-vars
export const ShoppingExpressBookingPageQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $pageTypeSlug: String
    $villageSlug: String
  ) {
    page: contentfulPageTemplateFormsT10(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      hero {
        eyebrow
        headline
        ...heroMediaQuery
      }
      locale: node_locale
      formMetaData {
        __typename
        ... on ContentfulLabelShoppingExpressLab07 {
          heading
          dateOfTravel
          viewTimetable
          journeyType
          departureLocation
          departureTime
          returnTime
          ticketTypesHeading
          promotionalCode
          checkoutNow
          addToMyServices
          promoCode
        }
      }
      village {
        name
        code
        villageSlug: slug
        currency
        servicesHeader {
          ...header
        }
        home: page_template_home_t01 {
          footer {
            ...footer
          }
          locale: node_locale
        }
        openingHours {
          ...villageOpeningHours
        }
        defaultLocale
        openingStatusLabel
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
  }
`

export default ShoppingExpressBookingPage
